<template>
  <!-- 小程序 - 运营 - 机构列表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">机构管理(小程序)</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">机构列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="学员姓名" class="searchboxItem">
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="form.percentage"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div>
              <el-button class="bgc-bv" @click="getData()">查询</el-button>
              <el-button class="bgc-bv" @click="exportList">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="学员姓名"
                align="center"
                prop="realname"
                show-overflow-tooltip
              />
              <el-table-column
                label="性别"
                align="center"
                prop="sex"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.sex == 1 ? "男" : "女"
                }}</template>
              </el-table-column>
              <el-table-column label="联系方式" align="center" prop="phone" />
              <el-table-column label="工作单位" align="center" prop="company" />
              <el-table-column label="所属岗位" align="center" prop="post" />
              <el-table-column
                label="行政区划"
                align="center"
                prop="areaName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="地址"
                align="center"
                prop="address"
                show-overflow-tooltip
                min-width="100"
              />       
              <!-- <el-table-column
                label="所属单位"
                align="center"
                prop="withdrawAmount"
              /> -->
              <el-table-column
                label="报名时间"
                align="center"
                prop="applyTime"
              />
              <el-table-column
                label="课程名称"
                align="center"
                prop="courseName"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { console } from "../../../utils/helper";
export default {
  name: "distributorList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      form: {
        percentage: "",
      },
    };
  },
  computed: {},
  // 过滤器
  filters: {
    getState(val) {
      let dic = {
        10: "待审核",
        20: "已通过",
        30: "已驳回",
      };
      return dic[val];
    },
  },
  methods: {
    // 获取机构列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        realname: this.form.percentage,
      };
      this.doFetch(
        {
          url: "/miniapp/course/apply/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 导出
    exportList() {
      this.$post(
        '/miniapp/course/apply/list/export',
        {
          realname: this.form.percentage,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
  },
};
</script>
<style lang="less"></style>
